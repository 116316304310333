// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function show(slug) {
  return "/locations/" + encodeURI(slug);
}

function edit(id) {
  return "/dashboard/countries/" + ID.toString(id) + "/edit";
}

var Dashboard = {
  index: "/dashboard/countries",
  fixCounts: "/api/v1/dashboard/countries/fix_counts",
  edit: edit
};

var unitedStates = "/locations/" + encodeURI("united-states");

var china = "/locations/" + encodeURI("china");

var germany = "/locations/" + encodeURI("germany");

var unitedKingdom = "/locations/" + encodeURI("united-kingdom");

var canada = "/locations/" + encodeURI("canada");

var index = "/locations/countries";

export {
  index ,
  show ,
  Dashboard ,
  unitedStates ,
  china ,
  germany ,
  unitedKingdom ,
  canada ,
}
/* unitedStates Not a pure module */

// extracted by mini-css-extract-plugin
export var bgSemiTransparentDark = "Spinner__bgSemiTransparentDark__I7RHU";
export var bgWhite = "Spinner__bgWhite__rCWUF";
export var centeredContainer = "Spinner__centeredContainer__YhHpi";
export var centeredWrapper = "Spinner__centeredWrapper__rvyMf";
export var colLeft = "Spinner__colLeft__d_yts";
export var colMiddle = "Spinner__colMiddle__zrQVh";
export var colRight = "Spinner__colRight__p2mF7";
export var colorBlack = "Spinner__colorBlack__AwwYp";
export var colorOrange = "Spinner__colorOrange__m7Boe";
export var colorPink = "Spinner__colorPink__UTTKT";
export var colorRed = "Spinner__colorRed__xOjqI";
export var colorRedDarker = "Spinner__colorRedDarker__USVIY";
export var colorTeal = "Spinner__colorTeal__vWnlz";
export var colorWhite = "Spinner__colorWhite__WBFdt";
export var column = "Spinner__column__JOwp1";
export var container = "Spinner__container__mOw1L";
export var row = "Spinner__row__Olul9";
export var spinner = "Spinner__spinner__N6pgk";
export var wrapper = "Spinner__wrapper__hrrgw";
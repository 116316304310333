// extracted by mini-css-extract-plugin
export var column = "Toggle__column__i1naB";
export var control = "Toggle__control__Qd_Kp";
export var disabled = "Toggle__disabled__BQUgp";
export var lg = "Toggle__lg__Mu6Se";
export var md = "Toggle__md__NXr1V";
export var off = "Toggle__off__fRXXo";
export var on = "Toggle__on__ozxHd";
export var row = "Toggle__row__XAwRu";
export var sm = "Toggle__sm__DKpy9";
export var toggle = "Toggle__toggle__yoiyo";
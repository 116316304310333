// Generated by ReScript, PLEASE EDIT WITH CARE


function visit(url) {
  window.location.href = url;
}

function visitBlank(url) {
  window.open(url);
}

export {
  visit ,
  visitBlank ,
}
/* No side effect */
